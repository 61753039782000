.stats-container > div {
    height: 17vh;
}

.stats-container > .Member_Card {
    background: #12ACCB;
}

.order-card-container.Cd_Section > .stats-card {
    padding: 2%;
}

.order-card-container.Cd_Section > .stats-card, .order-card-container.Cd_Section > .col > .stats-card {
    border-radius: 16px;
    cursor: default;
    display: flex;
    margin: 0 1%;
}

.order-card-container.Cd_Section > .stats-card:first-child {
    margin-left: 0;
}

.order-card-container.Cd_Section > .stats-card:last-child {
    margin-right: 0;
}

.order-card-container.Cd_Section > .stats-card:hover, .order-card-container.Cd_Section > .col > .stats-card:hover {
    background: inherit !important;
}

.Receno-Barr .DrpDown {
    width: 65%;
}

.Receno-Barr > div > .select {
    margin-left: 2%;
}

.mobile-stats-container > .stats-card {
    width: calc(100% / 3);
}

.graph-container {
    display: flex;
    margin: 5% 0;
}

.graph-container > div {
    width: 50%;
}

.graph-container > div:first-child {
    margin-right: 1%;
}

.graph-container > div:last-child {
    margin-left: 1%;
}

.CrdName {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #7A7A9D;
}

.Card_Numbers {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #16192C;
}

.react-datepicker__input-container, .react-datepicker__input-container > input {
    height: 100%;
}

.download-order-container {
    display: flex;
    margin-left: auto;
    width: 50%;
}

@media (max-width: 1000px) {
    .download-order-container {
        width: 75%;
    }
}

@media (max-width: 800px) {
    .seller-stats-container {
        display: block !important;
    }

    .seller-stats-container > .stats-card {
        margin: 5% 0 !important;
    }

    .number-stats-container {
        display: block !important;
    }

    .download-order-container {
        width: 100%;
        margin-top: 2%;
    }
}

@media (max-width: 600px) {
    .mobile-stats-container > .col {
        width: 100%;
        margin: 5% 0;
    }

    .graph-container {
        display: block;
    }

    .graph-container > div {
        width: 100%;
    }

    .download-order-container {
        display: block;
    }

    .download-order-container > * {
        margin: 2% 0 !important;
    }

    .download-order-container > button {
        padding: 3%;
    }

    .react-datepicker__input-container {
        height: 46px;
    }
}

@media (max-width: 400px) {
    .mobile-stats-container > .col > div > div:first-child {
        width: 25% !important;
    }

    .mobile-stats-container > .col > div > div:last-child {
        width: 75% !important;
    }
}

