#root {
    font-family: 'Inter';
    font-size: 18px;
}

button {
    cursor: pointer;
}

input {
    width: 100%;
    height: 46px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 6px;
    border-width: 0;
    padding: 0 2%;
}

.custom-file-label {
    background-color: #EEEEEE;
    border: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    cursor: pointer;
}

.custom-file, .custom-file-label, .custom-file-input {
    height: 25vh;
}

.small-input, .small-input > .custom-file-label, .small-input > .custom-file-input {
    height: 8vh;
    margin: 3% 0;
}

.small-input > .custom-file-label > img {
    width: 20%;
}

.small-input > .custom-file-label > .image-uploader-label {
    font-size: 12px;
}

.custom-file-label::after {
    display: none;
}

.custom-file-input {
    padding: 0 !important;
    cursor: pointer;
    z-index: 0;
}

.spinner-grow {
    margin-right: 2%;
}

.white-button {
    background: #FFFFFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    color: #16192C;
    border-width: 0;
}

.white-button:hover {
    background: #007BFF;
    color: white;
    border: 2px solid white;
}

.blue-button, .blue-border-button:hover {
    align-items: center;
    background: #007BFF;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.blue-button:hover {
    background: white;
    color: #16192C;
    border: 2px solid #007BFF;
}

.form-button {
    border: 2px solid transparent;
    width: 100%;
    margin-bottom: 5%;
}

.blue-border-button {
    align-items: center;
    background: transparent;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #16192C;
    border: 2px solid #007BFF;
}

.red-button, .red-border-button:hover {
    align-items: center;
    background: #FF2F64;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.red-button:hover {
    background: white;
    color: #16192C;
    border: 2px solid #FF2F64;
}

.green-button, .green-border-button:hover {
    align-items: center;
    background: #00df81;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
    border-color: transparent;
}

.green-button:hover {
    background: white;
    color: #16192C;
    border: 2px solid #00df81;
}

a.blue-button {
    border: 2px solid transparent;
}

.css-b62m3t-container {
    padding: 0;
}

@media (max-width: 1000px) {
    .modal-dialog > .modal-content {
        width: 50vw !important;
    }
}

@media (max-width: 600px) {
    .modal-dialog > .modal-content {
        width: 85vw !important;
    }
}

@media (max-width: 400px) {
    .blue-button, .blue-border-button {
        padding: 3%;
    }

    .modal-dialog > .modal-content {
        width: 95vw !important;
    }
}
