.main {
    text-align: center;
    justify-content: center;
    display: flex;
    background-color: #1E1E1E;
    min-height: 100vh;
}

.sub-main {
    text-align: center;
    justify-content: center;
    height: 65%;
    width: 424px;
    background: #F7FAFC;
    border-radius: 8px;
    left: 35.28%;
    right: 35.28%;
    margin: auto 5%;
    padding: 5%;
}

.img {
    /* Emoji -> */
    padding: 0 0 5%;
    width: 100%;
}

.mvno-wireless {
    color: #27272E;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
}

.loremIpsum {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #27272E;
    padding-top: 0;
}

.usernametxt {
    /* Forms/Label */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Text/Body/Light */
    color: #425466;
    padding: 0 1%;
    margin: 5% 0;
    height: 17px;
}

.passtxt {
    /* Forms/Label */
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Text/Body/Light */
    color: #425466;
    padding: 0 1%;
    margin: 5% 0;
    height: 17px;
}

.signinbtnn {
    width: 100%;
    height: 46px;
    background: #007BFF;
    border-radius: 8px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    border-width: 0;
    margin-top: 15%;
}

.login-alert {
    margin: 5% 0 -10%;
}

@media (max-height: 800px) {
    .sub-main {
        height: auto;
    }

    .signinbtnn {
        margin-bottom: 5%;
    }
}

@media (min-width: 1200px) {
    .sub-main {
        padding: 2%;
    }
}

