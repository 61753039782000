.Nav-Bar {
    width: 100%;
    display: flex;
    align-items: center;
    background: #F2F5F7;
    padding: 2%;
}

.user-detail-container {
    margin-left: auto;
    width: 20%;
    display: flex;
    align-items: center;
}

.user-details {
    display: flex;
    align-items: center;
    width: 85%;
    cursor: pointer;
}

.Nav-Bar-Pagetxt {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #27272E;
}

.Notification-Bell {
    position: fixed;
    width: 24px;
    height: 24px;
    padding-left: 58.3%;
    top: 38px;
}

.Notification-Ping {
    position: fixed;
    width: 8px;
    height: 8px;
    padding-left: 59.2%;
    top: 39px;
}

.Name {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #171E26;
    margin-bottom: 0;
}

.Company {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #667280;
    margin-bottom: 0;
}

.User {
    height: fit-content;
}

.notificationbell {
    background: transparent url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23212933' xmlns='http://www.w3.org/2000/svg'><path d='M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z' stroke='%23212933' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round'/><path d='M14 3C13.6649 2.85714 13.3189 2.74603 12.9622 2.68254C11.9243 2.49206 10.9297 2.60317 10 3C10.3135 1.8254 11.0919 1 12 1C12.9081 1 13.6865 1.8254 14 3Z' stroke='%23212933' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M15 19C15 21.2 13.65 23 12 23C11.18 23 10.42 22.5467 9.88 21.8267C9.34 21.1067 9 20.0933 9 19' stroke='%23212933' stroke-width='1.5' stroke-miterlimit='10'/></svg>") center no-repeat;
    height: 3vh;
}

.notificationbell:hover {
    border: none;
}

.notificationbell:focus {
    border: none;
}

@media (max-height: 800px) {
    .notificationbell {
        height: 5vh !important;
    }
}

@media (max-height: 400px) {
    .notificationbell {
        height: 10vh !important;
    }
}

@media (max-width: 1000px) {
    .Nav-Bar {
        background: #17171B;
    }

    .Nav-Bar-Pagetxt {
        margin: auto;
        text-align: center;
    }

    .Nav-Bar-Pagetxt, .Name {
        color: white;
    }

    .user-detail-container {
        place-content: end;
        margin-left: 0;
    }

    .user-detail-container > div:first-child {
        width: 25% !important;
    }

    .user-details {
        width: auto;
        margin-right: 5%;
        margin-left: 10%;
    }

    .user-details > div {
        display: none;
    }

    .notificationbell {
        background: transparent url("data:image/svg+xml,<svg width='24' height='24' viewBox='0 0 24 24' fill='%23FFFFFF' xmlns='http://www.w3.org/2000/svg'><path d='M12.0201 2.90991C8.71009 2.90991 6.02009 5.59991 6.02009 8.90991V11.7999C6.02009 12.4099 5.76009 13.3399 5.45009 13.8599L4.30009 15.7699C3.59009 16.9499 4.08009 18.2599 5.38009 18.6999C9.69009 20.1399 14.3401 20.1399 18.6501 18.6999C19.8601 18.2999 20.3901 16.8699 19.7301 15.7699L18.5801 13.8599C18.2801 13.3399 18.0201 12.4099 18.0201 11.7999V8.90991C18.0201 5.60991 15.3201 2.90991 12.0201 2.90991Z' stroke='%23FFFFFF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round'/><path d='M14 3C13.6649 2.85714 13.3189 2.74603 12.9622 2.68254C11.9243 2.49206 10.9297 2.60317 10 3C10.3135 1.8254 11.0919 1 12 1C12.9081 1 13.6865 1.8254 14 3Z' stroke='%23FFFFFF' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/><path d='M15 19C15 21.2 13.65 23 12 23C11.18 23 10.42 22.5467 9.88 21.8267C9.34 21.1067 9 20.0933 9 19' stroke='%23FFFFFF' stroke-width='1.5' stroke-miterlimit='10'/></svg>") center no-repeat;
    }
}

@media (max-width: 600px) {
    .user-details {
        margin-left: 20%;
    }
}
