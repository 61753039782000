.Selandord {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #27272E;
    margin: 5% 0 2%;
}

.order-card-container > .col {
    margin: 1% 0;
}

.order-card {
    background: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 8px;
    width: 100%;
    padding: 5%;
    cursor: pointer;
}

.selected-order-card, .order-card:hover {
    background: #F8F9FA;
}

.Available_Box {
    background: #DEFFEE;
    border-radius: 6px;
    width: 50%;
    text-align: center;
    padding: 2%;
    margin-bottom: 5%;
}

.unavailable-box {
    background: #ffdede;
}

.available-later-box {
    background: #fff1de;
}

.Available_Txt {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    align-items: center;
    color: #66CB9F;
    margin-bottom: 0;
}

.unavailable-txt {
    color: #cb6666;
}

.available-later-txt {
    color: #cb9766;
}

.Card_Name {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #16192C;
}

.Order_Numbers {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #818C99;
}

.Main_Verizonphonenumber {
    background: #F8F9FA;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border-radius: 8px;
    margin: 5% 0;
    padding: 2%;
}

.Verizon_Logo {
    height: 46px;
    width: 46px;
    border-radius: 15px;
    margin-bottom: 2%;
}

.Main_Verizonphonenumber_Txt {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #27272E;
}

.Main_Verizonphonenumber_Detail {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #425466;
}

.Main_Verizonphonenumber_Number {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: right;
    color: #FF4949;
}

.Dropdown_Box {
    text-align: center;
    font-style: normal;
    background: #FFFFFF;
    border: 1px solid #EBEFF2;
    border-radius: 8px;
    font-weight: 400;
    font-size: 13px;
    width: 45%;
    margin-right: 2%;
}

.QuantityBox {
    background: #FFFFFF;
    border: 1px solid #EBEFF2;
    border-radius: 6px;
    width: 45%;
    margin-right: 2%;
}

.Dropdown_Box > select, .QuantityBox > input {
    width: 100%;
    height: 100%;
}

.QuantityTxt {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    color: #7A828A;
}

.Ordr_Btn {
    width: 10%;
}

@media (max-width: 1200px) {
    .order-card-container > .col {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .order-card-container > .col {
        width: 100%;
    }

    .Main_Verizonphonenumber > div {
        display: block !important;
    }

    .Main_Verizonphonenumber > div > div {
        width: 100% !important;
    }
}
